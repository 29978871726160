define("discourse/plugins/discourse-assign/discourse/components/assign-user-form", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/discourse-assign/discourse/components/assignment", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _assignment, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AssignUserForm;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AssignUserForm = _exports.default = (_class = (_AssignUserForm = class AssignUserForm extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "showValidationErrors", _descriptor, this);
      this.args.formApi.submit = this.assign;
    }
    get assigneeIsEmpty() {
      return !this.args.model.username && !this.args.model.group_name;
    }
    async assign() {
      if (this.assigneeIsEmpty) {
        this.showValidationErrors = true;
        return;
      }
      await this.args.onSubmit();
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <Assignment
        @assignment={{@model}}
        @onSubmit={{this.assign}}
        @showValidationErrors={{this.showValidationErrors}}
      />
    
  */
  {
    "id": "DEZrvZWn",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@assignment\",\"@onSubmit\",\"@showValidationErrors\"],[[30,1],[30,0,[\"assign\"]],[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n  \"]],[\"@model\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/components/assign-user-form.js",
    "scope": () => [_assignment.default],
    "isStrictMode": true
  }), _AssignUserForm), _AssignUserForm), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "assign", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "assign"), _class.prototype)), _class);
});